import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService, ICustomerInvoice } from '../payment.service';
import Swal from 'sweetalert2';
import { Invoice } from '@axenda/zatca';
import { retry } from 'rxjs/operators';
import { TrackingService } from 'app/core/services/tracking.service';
import { decode } from 'app/utils';



@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {
  invoiceId: any;
  invoiceData: any;
  payLoader: boolean;
  lang: any;
  imageData:any;
  userId:any
  constructor(
    private _trackingService: TrackingService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private paymentService: PaymentService
  ) {
    this.route.params.subscribe(
      (params) => {
        this.invoiceId = params.invoiceId;
        if (!this.invoiceId || this.invoiceId.trim() === null) {
          this.router.navigate(['e-services']);
        } else {
          this.getInvoiceDetails();
        }
      },
      (err) => {
        this.router.navigate(['e-services']);
      }
    );

    this.lang = localStorage.getItem('lang');
  }

  ngOnInit() {
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser')
     } else{
      this.userId = null
     }
  }

  async getQrCode(){

    const invoice = new Invoice({
      sellerName: 'United Doctors Hospital',
      vatRegistrationNumber: '300185964100003',
      invoiceTimestamp:  this.invoiceData.createdAt,
      invoiceTotal: this.invoiceData.netAmount,
      invoiceVatTotal: this.invoiceData.vatAmount
    });
    invoice.toTlv();
    invoice.toBase64();
    this.imageData = await invoice.render();
  }

  getInvoiceDetails() {
    this.paymentService
      .getInvoiceDetailsById(this.invoiceId)
      .subscribe((invoice) => {
        this.invoiceData = invoice;
        this.getQrCode();
        const idNumber: string = this.invoiceData.idNumber;
        this.invoiceData.idNumber = idNumber.replace(
          idNumber.substring(1, 7),
          '******'
        );
      }, (err)=>{
        Swal({
          title: `${this.translate.instant('Invalid Invoice')}`,
          text: `${this.translate.instant(err.error.message)}`,
          type: 'error',
          confirmButtonColor: '#2740b6',
        })
      });
  }
  goToDetails() {
    this.router.navigate([`payments/checkout/${this.invoiceId}`]);
  }
  addTrackingLog(){
    this._trackingService.addTrackingLog(this._trackingService.tracking_type.PAY_ONLINE, this.userId? this.userId.id : "").subscribe(data =>{
      return data
    })
  }

  placeOrder() {
    if (this.invoiceId && this.invoiceData) {
      this.payLoader = true;
      const paymentGeteWay = this.invoiceData.paymentGateWay;

      switch (paymentGeteWay) {
        case 1:
          this.getTelrPaymentPage();
          break;
        case 2:
          this.getHyperpayPaymentPage();
          break;
        default:
          this.getTelrPaymentPage();
          break;
      }
    }
  }
  getTelrPaymentPage() {
    const order: ICustomerInvoice = {
      customerIdNumber: this.invoiceData.idNumber,
      customerMobileNumber: this.invoiceData.mobileNumber,
      customerProfileNumber: this.invoiceData.profileNumber,
      transCount: this.invoiceData.transCount,
    };
    this.paymentService.getTelrPaymentPage(order).pipe(retry()).subscribe((newOrder) => {
      if (newOrder && newOrder.order) {
        window.location.href = newOrder.order.url;
      }
    });
  }
  getHyperpayPaymentPage() {
    const order: ICustomerInvoice = {
      customerIdNumber: this.invoiceData.idNumber,
      customerMobileNumber: this.invoiceData.mobileNumber,
      customerProfileNumber: this.invoiceData.profileNumber,
      transCount: this.invoiceData.transCount,
    };
    this.paymentService.getHyperPayPaymentPage(order).pipe(retry()).subscribe((newOrder) => {
      if (newOrder && newOrder.checkoutId) {
        this.router.navigate(['payments/pay/' + newOrder.checkoutId]);
      }
    });
  }
}
