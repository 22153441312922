import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { decode } from 'app/utils';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  tracking_type = {
    ALLERGIES: "020x1z7a-7486-4304-88c4-8f988b65acd5",
    LAB_RESULT: "021x1z7a-7486-4304-88c4-8f988b65acd5",
    RADIOLOGY_RESULT: "022x1z7a-7486-4304-88c4-8f988b65acd5",
    DRAGS: "025d2k3a-3486-4304-88c4-8f988b65acd5",
    BOOK_APPOINTMENT: "025m2l3a-3486-4304-88c4-8f988b65acd5",
    PAY_ONLINE: "025m9l8a-3486-4304-88c4-8f988b65acd5"
  }
  httpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      'authorization': 'ak ' + environment.key,
      'jwt-token': decode('token')
    
    }),
  }
  constructor(private httpClient: HttpClient) { }

  addTrackingLog(trckingType:any, userId:any){
    return this.httpClient.post(`${environment.paymentApiUrl}/tracking/trackingLog?id=${trckingType}&channel=1&userId=${userId}`, null, this.httpOptions)
  }


}
