import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-canceled',
  templateUrl: './canceled.component.html',
  styleUrls: ['./canceled.component.scss'],
})
export class CanceledComponent implements OnInit {
  transCount: any;
  invoiceData: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private paymentService: PaymentService
  ) {
    this.route.params.subscribe(
      (params) => {
        this.transCount = params.transCount;
        // console.log('invoice Number = ', this.transCount)
        if (!this.transCount || this.transCount.trim() === null) {
          this.router.navigate(['e-services']);
        } else {
          this.getInvoiceId();
        }
      },
      (err) => {
        // console.log('Error is: ', err)
        this.router.navigate(['e-services']);
      }
    );
  }
  getInvoiceId() {
    this.paymentService
      .getInvoiceIdByTransCount(this.transCount)
      .subscribe((res) => {
        this.router.navigate([`payments/invoices/${res.id}`]);
      });
  }
  ngOnInit() {}
}
