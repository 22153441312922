export const environment = {
  production: false,
  // apiUrl: 'http://localhost:4000/api/v1',
  // apiUrl: 'http://104.248.132.157:4000/api/v1',
  apiUrl: 'https://app.udh.sa',
  // paymentApiUrl: 'http://localhost:4000/api/v1',
  paymentApiUrl: 'https://app.udh.sa/api/v1',
  // paymentApiUrl: 'http://104.248.132.157:4000/api/v1',
  key: '2gNBG5t8rBqpkzSGFdu4C2:3b13LeJxgxnx0ELdyF0L5K',
  recaptchaSecret: '6LfDqn0UAAAAAOF7K8_U54lmRfL7M0DjUZxvRM0Z',
  recaptchaVerifyApi: 'https://www.google.com/recaptcha/api/siteverify',
  recaptchaSite: '6LfDqn0UAAAAAG_TdD-kOu5t1iUHt902vsi32LdB',
  agora: {
    appId: '3c2103c26c834007a1a34702d5917e69',
  },
  firebase: {
    apiKey: 'AIzaSyB4BBdi1vJYnCbD5VaAPII06vskHCseJ10',
    authDomain: 'giftstore-c2cb2.firebaseapp.com',
    projectId: 'giftstore-c2cb2',
    storageBucket: 'giftstore-c2cb2.appspot.com',
    messagingSenderId: '980030829818',
    appId: '1:980030829818:web:344f19346084e5f60e8543',
    measurementId: "G-4RVGX4K5QT"
  }
};
