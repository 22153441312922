import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {Full_ROUTES} from './shared/routes/full-layout.routes';
import {UserType} from './core/enums/user-type.enum';
import {AuthGuard, ReverseAuthGuard, RoleGuard} from './core/guards/auth.guard';
import {InvoicesComponent} from './payments/invoices/invoices.component';
import { CheckoutComponent } from './payments/checkout/checkout.component';
import { DoneComponent } from './payments/done/done.component';
import { ErrorComponent } from './payments/error/error.component';
import { CanceledComponent } from './payments/canceled/canceled.component';
const appRoutes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/e-services', pathMatch: 'full' },
      {
        path: 'admin',
        data: {
          role: [UserType.Admin, UserType.GiftStoreManagement, UserType.RestaurantManagement, UserType.GalleryManagement, UserType.CustomerServices, UserType.DepartmentsManagers],
        },
        canActivate: [RoleGuard],
        loadChildren: './admin/admin.module#AdminModule'
      },
           {
        path: 'payments',
        component: FullLayoutComponent,
        data: { title: 'المدفوعات الإلكترونية' },
        loadChildren: './payments/payments.module#PaymentsModule',
      },
     
    ]
  },
  {
    path: 'p/:invoiceId',
    redirectTo: 'payments/invoices/:invoiceId',
    pathMatch: 'full',
  },

  {
    path: 'payments',
    component: FullLayoutComponent,
    children: [
      {
        path: 'invoices/:invoiceId',
        component: InvoicesComponent,
        data: {
          title: 'الفواتير',
        },

      },
      {
        path: 'done/:transCount',
        component: DoneComponent,
        data: {
          title: 'المدفوعات الإلكترونية',
        },
      },
      {
        path: 'failed/:transCount',
        component: ErrorComponent,
        data: {
          title: 'المدفوعات الإلكترونية',
        },
      },
      {
        path: 'canceled/:transCount',
        component: CanceledComponent,
        data: {
          title: 'المدفوعات الإلكترونية',
        },
      },
    ]
  },

  {
    path: 'payments',
    component: FullLayoutComponent,
    children: [
      {
        path: 'checkout/:invoiceId',
        component: CheckoutComponent,
        data: {
          title: 'ملخص الفاتورة',
        },
      }
    ]
  },

  {
    path: 'e-services',
    component: FullLayoutComponent,
    canActivate: [ReverseAuthGuard],
    data: { title: 'مستشفى الأطباء المتحدون' },
    children: Full_ROUTES,
  },

  {
    path: '**',
    redirectTo: 'e-services',
    pathMatch: 'full',
  },
  // {
  //   path: '',
  //   component: ContentLayoutComponent,
  //   data: { title: 'content Views' },
  //   children: CONTENT_ROUTES,
  //   canActivate: [AuthGuard]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
