export enum UserType {
  All = 0,
  Admin = 1,
  GiftStoreManagement = 2,
  Patient = 3,
  RestaurantManagement = 4,
  GalleryManagement = 5,
  CustomerServices = 6,
  DepartmentsManagers = 7,
}
