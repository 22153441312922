import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  transCount: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private paymentService: PaymentService
  ) {
    {
      this.route.params.subscribe(
        (params) => {
          this.transCount = params.transCount;
          if (!this.transCount || this.transCount.trim() === null) {
            this.router.navigate(['e-services']);
          } else {
            this.getInvoiceId();
          }
        },
        (err) => {
          this.router.navigate(['e-services']);
        }
      );
    }
  }
  ngOnInit() {}
  getInvoiceId() {
    this.paymentService
      .getInvoiceIdByTransCount(this.transCount)
      .subscribe((res) => {
        setTimeout(() => {
          this.router.navigate([`payments/invoices/${res.id}`]);
        }, 2000);
      });
  }
}
