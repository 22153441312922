import {Component, OnInit} from '@angular/core';
import {ROUTES} from './sidebar-routes.config';
import {SideBarAction} from './sidebar.metadata';
import {Router, ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {decode} from '../../utils';
import {AuthService} from '../../pages/content-pages/login/auth.service';
import {UserType} from '../../core/enums/user-type.enum';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  private user: any;
  userType: number = UserType.All;
  routes: any[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private authService: AuthService,
  ) {
    this.authService.currentUser.subscribe(val => {
      this.user = val;
      this.routes = ROUTES;
      if (this.authService.currentUserValue) {
        const userRole = this.authService.currentUserValue.userType;
        if (userRole === UserType.Admin) {
          this.userType = UserType.Admin;
        } else if (userRole === UserType.GiftStoreManagement) {
          this.userType = UserType.GiftStoreManagement;
        } else if (userRole === UserType.RestaurantManagement) {
          this.userType = UserType.RestaurantManagement;
        } else if (userRole === UserType.GalleryManagement) {
          this.userType = UserType.GalleryManagement;
        }  else if (userRole === UserType.Patient) {
          this.userType = UserType.Patient;
        } else if (userRole === UserType.CustomerServices) {
          this.userType = UserType.CustomerServices;
        } else if (userRole === UserType.DepartmentsManagers) {
          this.userType = UserType.DepartmentsManagers;
        }
      } else {
        this.userType = UserType.All;
      }
      this.renderSidebarItem();
    });

    this.user = decode('currentUser');
  }

  ngOnInit() {
    $.getScript('./assets/js/perfect-scrollbar.jquery.min.js').then((r) => {
      $.getScript('./assets/js/app-sidebar.js');
    });
    this.renderSidebarItem();
  }

  renderSidebarItem(submenu?) {
    if (submenu) {
      return submenu.filter((item) => {
        if (item.role.indexOf(this.userType) !== -1) {
          return item;
        }
        return
      });
    } else {
      this.menuItems = this.routes.filter((menuItem) => {
        if (menuItem.type === 'logout' && !this.user) {
          return;
        } else {
          if (menuItem.role.indexOf(this.userType) !== -1) {
            if (menuItem.submenu.length) {
              menuItem.submenu = this.renderSidebarItem(menuItem.submenu);
            }
            return menuItem;
          }
          return;
        }
      });
    }
  }

  callLevel1Toggle($event: any, action?: SideBarAction) {
    if (action && action.type === 'logout') {
      action.action(this.authService);
      this.renderSidebarItem();
    }
  }
}
