import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-done',
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.scss'],
})
export class DoneComponent implements OnInit {
  transCount: any;
  invoiceData: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private paymentService: PaymentService
  ) {
    this.route.params.subscribe(
      (params) => {
        this.transCount = params.transCount;
        if (!this.transCount || this.transCount.trim() === null) {
          this.router.navigate(['e-services']);
        } else {
          this.getInvoiceId();
        }
      },
      (err) => {
        this.router.navigate(['e-services']);
      }
    );
  }

  getInvoiceId() {
    this.paymentService
      .getInvoiceIdByTransCount(this.transCount)
      .subscribe((res) => {
        setTimeout(() => {
          this.router.navigate([`payments/invoices/${res.id}`]);
        }, 2000);
      });
  }
  ngOnInit() {}
}
